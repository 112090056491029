@use './color';

#root {
  height: 100vh;
  overflow-y: scroll;
}

.grid-container {
  display: grid;
  grid-template-areas:
    "blubar"
    "pre-header"
    "header"
    "post-header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 0.5rem 2rem 7rem 2rem 1fr 5rem;
}

main {
  grid-area: main;
  padding: 1rem; 
}

.blubar {
  grid-area: blubar;
  background-color: #75bcfd;
}
