.post-header {
  grid-area: post-header;
  background-color: #2f5780;
  color: #eff1ef;

  a {
    color: #f0c040;
    &:hover {
      color: #bcf7aa;
    }
  }
}
