.welcome-banner {
  background: #efefef;
  // border: 2px solid #d2d2d2;
  border-radius: 0.5rem;
  color: #232323;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem auto;
  max-width: 1200px;
  h2 {
    font-size: 1.4rem;
    font-weight: 600;
    color: #646464;
  }
}

.logos-container {
  display: flex;
  margin: 0 auto;
  gap: 1rem;
}