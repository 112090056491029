/* width */
::-webkit-scrollbar {
  width: 8px;
  // display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);  

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ababab;
  border-radius: 3px;
  &:hover {
    background: #555;
  }
}