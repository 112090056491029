@use '../../general/color';

header {
  grid-area: header;
  background-color: color.$primary;
  &.row {
    height: 100%;
  }
  .brand-container {
    display: flex;
    align-items: center;
  }
  a {
    color: #ffffff;
    padding: 1rem;
    &.brand {
      color: #ffffff;
      font-size: 3rem;
      font-weight: bold;
    }
  }
}

button.open-sidebar {
  font-size: 3rem;
  padding: 0.2rem 0.5rem;
  background: none;
  color: #ffffff;
  border: none;
  cursor: pointer;
  &:hover {
    outline: 2px solid white;
  }
}

.header-nav, .header-actions {
  display: flex;
  align-items: center;
}

.header-nav {
  padding-left: 1rem;
  width: 50%;
  justify-content: space-between;
  .searchbox-container {
    transform: translateX(50%);
  }
}
.header-actions {
  padding-right: 1.8rem;
  gap: 1rem;
}