img {
  border-radius: 0.5rem;
  &.small {
    max-width: 5rem;
    width: 100%;
  }
  &.medium {
    max-width: 29rem;
    width: 100%;
  }
  &.large {
    width: 100%;
  }
}

.slide.selected .img-not-found {
  height: 280px;
  width: 100%;
  object-fit: cover;
}

.emoji {
  margin: 0 0.5rem;
}

svg {
  margin: 10px auto;
}


.cart-container {
  position: relative;
  display: block;
  padding: 0;
}
.cart-icon {
  height: 30px;
  width: 80px;
  height: 40px;
}
.badge {
  position: absolute;
  right: 1rem;
  background-color: #f02020;
  color: #ffffff;
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  // margin-left: -10px;
}