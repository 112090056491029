input,
select,
textarea,
button {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.2rem #a4a4a4 solid;
  font-size: 1.2rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:hover {
    // outline instead of border to prevent inputs from moving
    outline: 0.1rem solid #9c9b9b;
  }
}

select {
  margin-left: 1rem;
  padding: 0.5rem;
}
button {
  background-color: #f8f8f8;
  cursor: pointer;
  &.primary {
    background-color: #f0c040;
  }
  &.primary.blu {
    background-color: #6ccef5;
  }
  &.primary.blu.big {
    font-size: 1.65rem;
  }
  &.block {
    width: 100%;
  }
  &.small {
    font-size: 1.2rem;
  }
}

.filter {
  background-color: #caf0f8;
  padding: 0.4rem;
  margin: 0 0.4rem;
  &.active {
    background-color: #dff3f7;
    padding: 0.6rem;
    box-shadow: 5px 5px 8px #c7c7c7;
    text-decoration: underline;
  }
}

.active {
  font-weight: bold;
}

.image_input {
  margin-bottom: 0.5rem;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}