@use '../general/color';

@media screen and (min-width: 808px) and (max-width: 880px) {
  header.row {
    .brand {
      font-size: 2.5rem;
    }
    .header-actions {
      font-size: 1.3rem;
    }
  }
}


@media screen and (min-width: 675px) and (max-width: 808px) {
  .grid-container {
    display: grid;
    grid-template-areas:
      "blubar"
      "pre-header"
      "header"
      "post-header"
      "main"
      "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 0.5rem 2rem 7rem 2rem 1fr 5rem;
  }

  header.row {
    // flex-direction: column;
    .brand {
      font-size: 2rem;
    }
    .header-actions {
      font-size: 1.3rem;
    }
  }

  .schede__container {
    grid-template-columns: 25fr 25fr;
  }
}
@media screen and (max-width: 720px) {
  .grid-container {
    grid-template-rows: 0.5rem 2rem 10rem 2rem 1fr 5rem;
  }
  header.row {
    flex-direction: column;
    justify-content: center;
    .brand {
      font-size: 2rem;
    }
    .header-actions {
      font-size: 1.3rem;
      width: 100%;
      justify-content: space-between;
      svg {
        margin: 0;
      }
    }
  }
  .header-nav {
    width: 100%;
    padding-right: 1.8rem;
  }
}
@media screen and (max-width: 675px) {

  .welcome-banner {
    background: none;
    border: none;
  }

  .schede__container {
    grid-template-columns: 25fr 25fr;
    h1 {
      padding-top: 1rem !important;
    }
  }

  main {
    grid-area: main;
    padding: 0.3rem;
    width: 100vw;
  }

  .product-page {
    &__info {
      width: 100%;
      .p_sp {
        width: 100%;
      }
    }
  }

  img.medium {
    max-width: none;
  }

  .card {
    // width: 100%;
    &__img-container {
      background-color: color.$img-background;
    }
    img {
      object-fit: contain;
    }
  }

  .card--cart {
    width: 100% !important;
    margin: 2rem 0;
  }

  .card-body {
    width: 87vw;
  }

  .col-1.space {
    display: contents;
    width: 10vw;
  }

  .p_sp {
    width: 100vw;
  }

  .col-1 {
    width: 150vw;
  }

  .flash {
    padding: 4rem 0 1.2rem 0;
  }

  .mobile_sp {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .post-header {
    height: 6rem;
    text-align: center;
  }

  h1.row.center {
    margin: 2rem auto 0;
    text-align: center;
    font-size: 1.5rem;
    justify-content: center;
  }

  .white-force {
    height: 1vh;
    z-index: -10000;
    background-color: white;
  }

  .carousel-root {
    width: 94vw;
  }

  .example1 {
    font-size: 0.7rem;
    padding-top: 5px;
    width: 100vw;
  }

  h1.row.center {
    padding: 0;
    margin-top: 0;
    text-align: center;
  }

  h2.row.center {
    padding: 0px;
    margin-top: 0;
    text-align: center;
  }

  .html {
    width: 100vw;
  }

  .footer {
    width: 100vw;
    display: block;
  }
}
