@use './../general/color';

.schede__container {
  display: grid;
  grid-template-columns: 25fr 25fr 25fr 25fr;
  margin: 2rem auto;
  width: 100%;
  max-width: 1200px;
  gap: 2rem;
  .scheda_sp {
    overflow: hidden;
    width: 100%;
    height: 40rem;
    background-color: color.$background;
    font-size: 1.5rem;
    border-radius: 0.8rem;  
    transition: transform 0.3s;

    &:hover {
      transform: translateY(-1%);
      box-shadow: 0px 2px 4px lighten(#c0c0c0, 20%);
      ::-webkit-scrollbar-thumb {
        background-color: #ababab
      }
    }

    &__elements {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    h1 {
      padding: 1rem 0.5rem;
      // border-bottom: 1px solid rgb(173, 173, 173);
      box-shadow: 0 1px 1px rgb(192, 192, 192), ;
      text-align: center;
      word-break: break-word;
    }

    &__img-container {
      background-color: rgb(184, 184, 184);
      border-radius: 0.8rem 0.8rem 0 0;  
      img {
        width: 100%;
        height: 14rem;
        object-fit: contain;
      }
    }

    &__description-container {
      // height: 22.8rem;
      height: 100%;
      overflow-y: scroll;
      padding: 1rem;
      padding-left: 1.6rem;
      p {
        word-break: break-word;
      }
      &::-webkit-scrollbar {
        width: 6px;
        &-thumb {
          background-color: transparent;
        }
        &-track {
        border-radius: 0.8rem;
        background-color: inherit;
      }
    }
      p {
        line-height: 1.3;
      }
    }


  }
}

.scheda {
  background-color: #e3e3e4;
  padding: 1rem;
  margin: 1rem;
  width: 15vw;
  display: inline-flex;
  font-size: 1.5rem;
  overflow-x: scroll;
  border-radius: 0.8rem;
}

.scheda img {
  width: 10vw;
  margin: auto;
}

.scheda p {
  color: #000;
}






.scheda_sp p {
  color: #000;
}
