
@media screen and (max-width: 1450px) {
  .logo {
    height: 4.5rem;
    border-radius: 10rem;
    margin-top: 2px;
  }
}

@media screen and (max-width: 1330px) {
  .logo {
    display: none;
  }
  
}

@media screen and (min-width: 1150px) {
  .searchbox-container {
    input {
      width: 20vw;
    }
  }
}

@media screen and (max-width: 1150px) {
  .schede__container {
    grid-template-columns: 25fr 25fr 25fr;
  }  

  .header-nav {
    width: 59%;
    .searchbox-container {
      transform: none;
    }
  }
  .header-actions {
    gap: 0
  }
}

