@use "../general/color";

aside {
  position: fixed;
  width: 30rem;
  height: 100%;
  // background-color: #dceaff;
  background-color: color.$background;
  z-index: 1000;
  transform: translateX(-30rem);
  transition: all 0.5s;
  overflow: auto;
  &::-webkit-scrollbar-track {
    // background-color: #dceaff;
    background-color: color.$background;
  }
  
  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: color.$primary;
    color: #fff;
    padding: 1rem 1.8rem;
  }

  ul {
    padding: 1rem;
    list-style: none;


  }
  li {    
    padding: 0.8rem;
    border-bottom: 1px solid gray;
  }
}

aside.open {
  transform: translateX(0);
}

.sp_li {
  margin-top: 0.1rem;
}

button.close-sidebar {
  padding: 0.3rem 0.8rem;
  border: none;
  transition: 0.1s;
  &:hover {
    outline: 3px solid #6ccef5;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(128, 128, 128, 30%);
  z-index: 500;
  display: none;
  &.active {
    display: block;
  }
}