@use './../general/color';

.cart {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__container {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__item {
    height: 12rem;
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    align-items: center;
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 0;
    background-color: color.$background;
    transition: transform 0.3s;
    &:hover {
      transform: translateY(-1%)
    }


    &__img-container {
      background-color: color.$img-background;
      height: 100%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__description-container {
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      word-break: break-word;
    }
    
    &__price-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: center;
    }

    &__actions-container {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-end;
      height: 100%;

      select {
        border: 1px solid rgb(150, 150, 150);
        background-color: inherit;
        &:hover {
          outline: none;
          background-color: #fff;
        }
      }

      &__delete-btn__container {
        height: 100%;
        button {
          background-color: color.$delete;
          height: 100%;
          border: none;
          border-radius: 0;
          &:hover {
            outline: none;
            background-color: color.$delete-active;
          }
        }
      }
    }
  }
}