.dropdown {
  display: inline-block;
  position: relative;
  &:hover .dropdown-content {
    display: block;
  }
  &.last-element {
    margin: 0;
  }
}
.dropdown-content {
  position: absolute;
  display: none;
  // right: -65px;
  right: 0;
  width: 20rem;
  
  padding: 1rem;
  z-index: 1;
  background-color: #203040;
  margin: 0;
  margin-top: 1rem;
  border-radius: 0.5rem;
  &.admin {
    right: 0px;
    min-width: 18rem;
    max-width: 19rem;
  }
}
